const { numberFormat } = require("./helper");
const { getTranslatedValue } = require("./translationHelper");

exports.formatPrice = (property, currency) => {
  const { selling_price, rental_price, rental_mode } = property;

  if (!selling_price && !rental_price) {
    return null;
  }

  if (property.currency) {
    if (selling_price) {
      return property.currency.symbol + numberFormat(selling_price);
    } else {
      return (
        property.currency.symbol +
        numberFormat(rental_price) +
        (rental_mode ? "/" + rental_mode : "")
      );
    }
  }

  if (currency) {
    if (selling_price) {
      return currency.symbol + numberFormat(selling_price);
    } else {
      return (
        currency.symbol + numberFormat(rental_price) + (rental_mode ? "/" + rental_mode : "")
      );
    }
  }
};

exports.getTranslatedHeadline = (listing, locale, defaultLocale) => {
  let headline = getTranslatedValue(listing.descriptions, "headline", locale, defaultLocale);
  if (!headline) {
    headline = getTranslatedValue(listing.translations, "headline", locale, defaultLocale);
  }

  return headline;
};

exports.getTranslatedDescription = (listing, locale, defaultLocale) => {
  let description = getTranslatedValue(listing.descriptions, "description", locale, defaultLocale);
  if (!description) {
    description = getTranslatedValue(listing.translations, "description", locale, defaultLocale);
  }

  return description;
};
